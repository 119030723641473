import { Container } from '@material-ui/core'
import DefaultContentBlock from '../DefaultContentBlock'
import Screen from '../Screen'

const ScreenDesign = ({
  title,
  text,
}) => {
  return (
    <Screen>
      <Container>
        <DefaultContentBlock title={title} number='3' image={require('../../assets/img/f2.png')} defaultContent3 curve>
          {text}
        </DefaultContentBlock>
      </Container>
    </Screen>
  )
}

export default ScreenDesign
import { atom } from 'recoil'

export const quizQuestions =atom({
  key: `quiz/questions`,
  default: [],
})

export const quizQuestionIndex =atom({
  key: `quiz/questionIndex`,
  default: 0,
})
import Path from '../components/Path'
import ProjectsBlock from '../components/ProjectsBlock'
import Feedback from '../components/Feedback'
import Footer from '../components/Footer/'
import Robots from '../components/Robots'

import ScreenMain from '../components/screens/ScreenMain'
import ScreenProblem from '../components/screens/ScreenProblem'
import ScreenAudit from '../components/screens/ScreenAudit'
import ScreenPrototype from '../components/screens/ScreenPrototype'
import ScreenDesign from '../components/screens/ScreenDesign'
import ScreenDevelopment from '../components/screens/ScreenDevelopment'
import Header from '../components/Header'
import MetaTags from '@/components/MetaTags'


// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types,@typescript-eslint/no-unused-vars
const IndexPage = ({ labels, home, quiz, meta }) => {
  // const { t } = useTranslation(`common`)

  const {
    hero,
    hero_sub,
    main_header,
    main_text,
  } = home

  return (
    <>
      <MetaTags meta={meta} />

      <Header labels={labels} />

      <ScreenMain
        title={hero}
        subtitle={hero_sub}
      />

      <ScreenProblem
        title={main_header}
        text={main_text}
        quiz={quiz}
      />

      <Path>

        <ScreenAudit title={home.s1_title} text={home.s1_text} />
        <ScreenPrototype title={home.s2_title} text={home.s2_text} />
        <ScreenDesign title={home.s3_title} text={home.s3_text} />
        <ScreenDevelopment title={home.s4_title} text={home.s4_text} />
      </Path>

      <ProjectsBlock />

      <Feedback title={home.feedback_title} />

      <Robots />

      <Footer />
    </>
  )
}

export const getStaticProps = async ({ locale, defaultLocale }) => {
  const home = await import('@/content/pages/home.json').then(x => x.default) as any
  const labels = await import('@/content/pages/labels.json').then(x => x.default[locale] || {}) as any

  const meta = {
    ...home.meta,
    ...home?.meta?.[locale],
  }

  return {
    props: {
      labels,
      meta,
      home: home[locale] || home.de,
      quiz: await import('@/content/pages/quiz.json').then(x => x.default[locale] || {}),
    },
  }
}

export default IndexPage

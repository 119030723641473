import { quizQuestionIndex, quizQuestions } from '@/src/state/AppState'
import { makeStyles, Grid, Button, Checkbox, FormControlLabel, FormGroup, Typography, FormHelperText, TextField } from '@material-ui/core'
import axios from 'axios'
import { useCallback, useEffect, useState, useMemo } from 'react'
import ReactMarkdown from 'react-markdown'
import { useRecoilState } from 'recoil'
import theme from '../../../theme'


const useStyles = makeStyles({
  root: {
    position: 'relative',
    padding: '40px 54px',
    borderRadius: '24px',
    boxShadow: theme.shadows[24],
    margin: '3rem',
    [theme.breakpoints.down('sm')]: {
      padding: '32px 24px',
      marginLeft: '0',
      marginRight: '0',
    },
  },
  buttons: {
    fontSize: '1rem',
    marginTop: '1.75rem',
    [theme.breakpoints.down('sm')]: {
      marginTop: '1.7143rem',
    },
    '& button': {
      fontSize: '1rem',
      [theme.breakpoints.down('sm')]: {
        padding: '6px 20px',
      },
    },
  },
  buttonSubtitle: {
    fontWeight: 600,
    color: 'rgba(0, 0, 0, 0.5)',
    marginLeft: '1.875rem',
  },

  textField: {
    marginBottom: '1rem',
  },
})

const Quiz = ({
  quiz,
  className = '',
}) => {

  const {
    done_text,
    final_text,
  } = quiz

  const cls = useStyles()

  const [questions, setQuestions] = useRecoilState(quizQuestions)
  const [questionIndex, setQuestionIndex] = useRecoilState(quizQuestionIndex)
  const [question, setQuestion] = useState(() => questions[questionIndex])
  const [data, setData] = useState(() => ({ agree: true, from: '', result: {} }))
  const [isLoading, setLoading] = useState(false)
  const [isSuccessSubmit, setIsSuccessSubmit] = useState<boolean>(false)

  const handleAnswerChange = useCallback(e => {
    const { index } = e.currentTarget.dataset
    setData(p => {
      return {
        ...p,
        result: {
          ...p.result,
          [index]: {
            ...p.result[index],
            [e.target.value]: !!e.target.checked,
          },
        },
      }
    })
  }, [])

  const handleFieldChange = useCallback(({ target: { name, value, checked } }) => {
    setData(p => ({
      ...p,
      [name]: name ==='agree' ? checked : value,
    }))
  }, [])

  const isSubmitButtonEnabled = useMemo(() => {
    return (data.from?.length !== 0 && data.agree)
  }, [data])


  const handleSubmit = useCallback(e => {
    e.preventDefault()
    setLoading(true)


    const {
      result, // eslint-disable-line
      ...computedData
    } = {
      ...data,
      questions: Object.keys(data.result).map(index => {
        return {
          question: questions[index].question.title,
          answers: Object.keys(data.result[index]).filter(key => !!data.result[index][key]),
        }
      }),
    }


    axios.post(`/api/quiz`, computedData)
      .then(() => {
        //  alert('ok!')
      })
      .finally(() => {
        setIsSuccessSubmit(true)
        setQuestionIndex(p => p + 1)
        setTimeout(() => {
          setLoading(false)
        }, 500)
      })

  }, [data])

  const handleNextClick = useCallback(e => {
    setQuestionIndex(p => p+1)
  }, [])

  const handleBackClick = useCallback(e => {
    setQuestionIndex(p => p-1)
  }, [])

  useEffect(() => {
    setQuestion(questions[questionIndex])
  }, [questionIndex, questions])


  useEffect(() => {
    setQuestions(quiz.questions)
  }, [quiz])

  const total = questions.length

  return (
    <form className={`${cls.root} ${className}`} onSubmit={handleSubmit}>

      {!!question && (
        <>
          <FormHelperText> {questionIndex + 1} Frage von {total}</FormHelperText>
          <br />
          <Typography variant={'h3'}>{question.question.title}</Typography>
          <FormGroup>
            {question.question.text && (
              <Grid container>
                <Grid item sm={8} xs={12} md={6}>
                  <div className={cls.textField}>
                    <TextField
                      size='small'
                      fullWidth
                      variant='outlined'
                      name={`${question.question.text}`}
                      label={`${question.question.text}`}
                      onChange={handleFieldChange}
                    />
                  </div>
                </Grid>
              </Grid>
            )}
            <FormGroup className={cls.textField}>
              {question.question?.answers?.map(({ answer }, index) => (
                <FormControlLabel
                  key={`${questionIndex}-${index}`}
                  control={
                    <Checkbox
                      value={answer}
                      onChange={handleAnswerChange}
                      color='primary'
                      inputProps={{
                        'data-index': questionIndex,
                      } as any}
                    />
                  }
                  label={answer}
                />
              ))}
            </FormGroup>
            {question.question.text_answer && (
              <Grid container>
                <Grid item sm={8} xs={12} md={6}>
                  <div className={cls.textField}>
                    <TextField
                      size='small'
                      fullWidth
                      multiline
                      rows={3}
                      rowsMax={5}
                      variant='outlined'
                      name={`${question.question.text_answer}`}
                      label={`${question.question.text_answer}`}
                      onChange={handleFieldChange}
                    />
                  </div>
                </Grid>
              </Grid>
            )}
          </FormGroup>
        </>
      )}

      <div className={cls.buttons}>
        <Grid container alignItems={'center'}>
          {/* {questionIndex > total && (
            <>
              <Button type='submit' variant='contained' color='primary'>
                Submit
              </Button>
            </>
          )} */}
          {isSuccessSubmit && (
            <div style={{ marginTop: '15px' }}>
              <ReactMarkdown>{final_text}</ReactMarkdown>
            </div>
          )}

          {questionIndex === total && (
            <Grid item sm={8} xs={12} md={6}>
              <div className={cls.textField}>
                <ReactMarkdown>{done_text}</ReactMarkdown>
              </div>
              <div className={cls.textField}>
                <TextField
                  fullWidth
                  size='small'
                  variant='outlined'
                  name='from'
                  label={'Rufnummer oder E-Mail'}
                  onChange={handleFieldChange}
                  disabled={isLoading}
                />
              </div>
              <div className={cls.textField}>
                <TextField
                  fullWidth
                  size='small'
                  variant='outlined'
                  name='comment'
                  multiline
                  rows={3}
                  rowsMax={5}
                  label={'Kommentar'}
                  onChange={handleFieldChange}
                  disabled={isLoading}
                />
              </div>
              <div className={cls.textField}>
                <FormControlLabel
                  control={
                    <Checkbox
                      defaultChecked
                      name={'agree'}
                      onChange={handleFieldChange}
                      color='primary'
                    />
                  }
                  label={`Ich stimme den Richtlinien zur Verarbeitung personenbezogener Daten und dem Angebot auf der Website zu`}
                />
              </div>
              <Button disabled={!isSubmitButtonEnabled} type='submit' variant='contained' color='primary'>
                {!isLoading ? `Audit anforden` : `Entsendung...`}
              </Button>
            </Grid>
          )}
          {(questionIndex > 0 && questionIndex < total) && (
            <Button onClick={handleBackClick} variant='outlined' style={{ marginRight: '15px' }}>
              Zurück
            </Button>
          )}
          {questionIndex < total && (
            <Button onClick={handleNextClick} variant='contained' color='primary'>
              Weiter
            </Button>
          )}
        </Grid>
      </div>
    </form>
  )
}

export default Quiz

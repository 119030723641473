import { Container } from '@material-ui/core'
import DefaultContentBlock from '../DefaultContentBlock'
import Screen from '../Screen'

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
const ScreenPrototype = ({
  title,
  text,
}) => {
  return (
    <Screen>
      <Container>
        <DefaultContentBlock odd title={title} number='2' image={require('../../assets/img/f1.png')} defaultContent2 curve>
          {text}
        </DefaultContentBlock>
      </Container>
    </Screen>
  )
}

export default ScreenPrototype

import { Container, Grid, makeStyles, Typography } from '@material-ui/core'
import Screen from './Screen'
import theme from '../theme'
import Link from 'next/link'

const useStyles = makeStyles({
  root: {
    // [theme.breakpoints.down('md')]: {
    //   paddingBottom: '0',
    // },
    // [theme.breakpoints.down('xs')]: {
    //   paddingBottom: 'auto',
    // },
  },
  image: {
    [theme.breakpoints.down('sm')]: {
      maxWidth: '100%',
      height: 'auto',
      marginBottom: '30px',
    },
  },
  topLink: {
    position: 'relative',
    color: '#061226',
    textDecoration: 'none',
    display: 'inline-block',
    '&::before': {
      position: 'absolute',
      content: '""',
      width: '110%',
      height: '1px',
      left: '50%',
      bottom: '0',
      transform: 'translateX(-50%)',
      backgroundColor: 'rgba(0, 0, 0, 0.12)',
      transition: 'background-color 0.4s ease',
    },
    '& > span': {
      position: 'relative',
    },
    '&:hover': {
      '&::before': {
        backgroundColor: '#FFD600',
      },
    },
  },
  link: {
    position: 'relative',
    color: '#061226',
    textDecoration: 'none',
    borderBottom: '1px solid rgba(0, 0, 0, 0.12)',
    transition: 'border-color 0.4s ease',
    '&:hover': {
      borderBottomColor: '#FFD600',
    },
  },
})

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
const ProjectsBlock = () => {
  const cls = useStyles()
  return (
    <Screen className={cls.root}>
      <Container>
        <Grid container alignItems={'center'}>
          <Grid item xs={12}>
            <Typography variant={'h1'} align='center'>
              <Link href='/projects'><a className={cls.topLink} href='/projects'><span>Projekte</span></a></Link>
            </Typography>
          </Grid>
          <Grid item md={7}>
            <img className={cls.image} src={require(`../assets/img/p1.png`)} alt={'p1'} />
          </Grid>
          <Grid item md={5}>
            <Typography variant={'h2'}>
              <Link href='/project2'><a className={cls.link} href='/project2'><span>Eine Website über Steuern</span></a></Link>
            </Typography>
            <Typography>
              Langweilt Sie das Thema Steuern? Das geht vielen anderen auch so.
              Bei diesem Projekt haben wir diese Thematik so dargestellt, dass man erkennt, wie sich Chaos in den Händen des richtigen Steuerberaters sicher in Ordnung verwandelt.
              Schauen Sie sich an, wie wir das gelöst haben.
            </Typography>
          </Grid>
        </Grid>
      </Container>
    </Screen>
  )
}


export default ProjectsBlock

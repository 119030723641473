import { Button, Container, Grid, makeStyles, Typography } from '@material-ui/core'
import OfferImage from './OfferImage'
import Screen from '../../Screen'
import theme from '../../../theme'
import { useCallback } from 'react'
import { useRecoilState } from 'recoil'
import { quizQuestionIndex, quizQuestions } from '@/src/state/AppState'

const useStyles = makeStyles({
  wrapper: {
    [theme.breakpoints.down('sm')]: {
      paddingTop: '0',
    },
  },
  root: {
    paddingTop: '1.875rem',
    paddingBottom: '1.875rem',
  },
  titleWrapper: {
    position: 'relative',
  },
})

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
const ScreenMain = ({
  title,
  subtitle,
}) => {
  const cls = useStyles()
  const [questions] = useRecoilState(quizQuestions)
  const [,setQuestionIndex] = useRecoilState(quizQuestionIndex)

  const handleClick= useCallback(e => {
    setQuestionIndex(questions.length)
    if(typeof window === 'undefined') {return}
    const $quiz = document.querySelector(`#quiz`) as HTMLElement
    window.scroll({
      behavior: 'smooth',
      top: $quiz?.offsetTop,
    })
  }, [])

  return (
    <>
      <Screen className={cls.wrapper} id='main' first minHeight='500px'>
        <Container className={cls.root}>
          <Grid container spacing={5} alignItems={'center'}>
            <Grid item xs={12} md={6} className={cls.titleWrapper}>
              <Typography variant={'h2'}>
                {title}
              </Typography>
              <Typography variant={'subtitle2'}>
                {subtitle}
              </Typography>
              <Button onClick={handleClick} size='large' variant='contained' color='primary'>
                Audit anfordern
              </Button>
            </Grid>
            <Grid item xs={12} md={6}>
              <OfferImage
              />
            </Grid>
          </Grid>
        </Container>
      </Screen>
    </>
  )
}

export default ScreenMain

import { Container } from '@material-ui/core'
import DefaultContentBlock from '../DefaultContentBlock'
import Screen from '../Screen'

const ScreenDevelopment = ({
  title,
  text,
}) => {
  return (
    <Screen>
      <Container>
        <DefaultContentBlock odd title={title} number='4' image={require('../../assets/img/f3.png')} defaultContent4 dudeWithCar>
          {text}
        </DefaultContentBlock>
      </Container>
    </Screen>
  )
}

export default ScreenDevelopment
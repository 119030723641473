import { Container } from '@material-ui/core'
import DefaultContentBlock from '../DefaultContentBlock'
import Screen from '../Screen'


// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
const ScreenAudit = ({
  title,
  text,
}) => {
  return (
    <Screen id='audit' style={{
      //@hack
      marginTop: '-10rem',
    }}>
      <Container>
        <DefaultContentBlock
        // hero='Problemlösung'
          title={title} number='1' dudes={['Kunde', 'Möwe']} defaultContent1 curve>
          {text}
        </DefaultContentBlock>
      </Container>
    </Screen>
  )
}

export default ScreenAudit

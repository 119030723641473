// eslint-disable-next-line
// @ts-nocheck

import { makeStyles } from '@material-ui/core'
import BlackLabel from '../../BlackLabel'
// import BeforeAfter from '../../BeforeAfter/index'

const useStyles = makeStyles({
  root: {
    position: 'relative',
    textAlign: 'center',
    '& img': {
      maxWidth: '100%',
    },
  },
  image: {
    maxWidth: '100%',
    height: 'auto',
  },
  in: {
    position: 'absolute',
    left: '0',
    top: '0',
    width: '100%',
    height: '100%',
    padding: '0 15px 0 24px',
    display: 'grid',
    gridTemplateColumns: '44% 56%',
  },
  firstLabel: {
    justifySelf: 'flex-start',
    alignSelf: 'flex-start',
    marginTop: '15%',
  },
  secondLabel: {
    justifySelf: 'flex-end',
    alignSelf: 'flex-start',
    marginTop: '35%',
  },
})

const OfferImage = ({ className = '' }) => {
  const cls = useStyles()

  return (
    <div className={`${cls.root} ${className}`}>

      {/* <BeforeAfter
        firstImgSrc={require('../../../assets/img/visual-vw.png')}
        secondImgSrc={require('../../../assets/img/visual-f1.png')}
      /> */}

      <img src={require('../../../assets/img/visual.png')} />

      <div className={cls.in}>
        <BlackLabel className={cls.firstLabel}>alte Website</BlackLabel>
        <BlackLabel className={cls.secondLabel}>neue Website</BlackLabel>
      </div>
    </div>
  )
}

export default OfferImage

import { makeStyles } from '@material-ui/core'
import theme from '../theme'

const useStyles = makeStyles({
  root: {
    position: 'relative',
  },
  svg: {
    position: 'absolute',
    top: 0,
    left: 0,
    bottom: 0,
    right: 0,
    width: '100%',
    height: '100%',
    [theme.breakpoints.down('sm')]: {
      display: 'none',
    },
  },
})

const Path = ({
  children,
}) => {
  const cls = useStyles()
  return (
    <div className={cls.root}>
      <svg
        className={cls.svg}
        viewBox='0 0 1440 2534' fill='none' xmlns='http://www.w3.org/2000/svg'>
        <path d='M-73 128.231C649 -81.0001 1078.5 160 1078.5 512C1078.5 832.5 782 953.5 521.5 1067.73C261 1199.5 256.447 1494.74 559.5 1651C1056 1907 1174 1869 1513.5 2510' stroke='black' strokeOpacity='0.06' strokeWidth='400' />
      </svg>
      {children}
    </div>
  )
}

export default Path

import { Container, Grid, Typography } from '@material-ui/core'
import Screen from '../../Screen'
import Markdown from 'react-markdown'
import Quiz from './Quiz'

const ScreenProblem = ({
  title,
  text,
  quiz,
}) => {
  return (
    <Screen centered={true} minHeight='400px' id='quiz'>
      <Container>
        <Grid container alignItems={'center'}>

          <Grid item xs={12} >
            <Quiz quiz={quiz} />
          </Grid>

          <Grid item md={2} />
          <Grid item md={8}>

            <Typography variant={'h3'} align='center'>
              {title}
            </Typography>
            <Typography variant={'subtitle2'} align='center'>
              <Markdown>
                {text}
              </Markdown>
            </Typography>

          </Grid>
          {/* <Grid item md={6}>

            <Typography variant={'h3'}>
              Wo liegt das Problem?
            </Typography>

            <Typography variant={'subtitle2'}>
              Es kommt auf unterschiedliche Faktoren an. Das ist oft nicht nur ein Problem, sondern deren Zusammenhänge. Haben Sie vielleicht noch gar keine Website?
            </Typography>
          </Grid> */}

        </Grid>
      </Container>
    </Screen>
  )
}

export default ScreenProblem
